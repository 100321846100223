import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import EndpointFactory from '~~/app/factories/EndpointFactory';

export default function useModelLoader<M extends BaseModel>(modelType: ModelType, uuid: string, endpointParamsCallback?: Function) {
    const model = reactiveModel<M>(null);
    const loading = ref(true);

    const load = async () => {
        loading.value = true;

        const endpoint = EndpointFactory.make(modelType);

        const response = await endpoint.retrieve(uuid, endpointParamsCallback ? endpointParamsCallback() : {});

        const route = useRoute();

        if (response.error) {
            if (response.response.status == 404) {
                useToasteoError('toasts.model_not_found');
                return;
            }

            if (response.response.status == 401 || response.response.status == 403) {
                // TBD IF THIS IS A SOLUTION
                if (route.fullPath === '/dashboard') return;
                useToasteoError('toasts.unauthorized');
                return;
            }

            useToasteoError('toasts.model_error');
            return;
        }

        model.value = response.data;
        loading.value = false;
    };

    return {
        model,
        loading,
        load,
    };
}
